import { Entity, Input, Var } from "addeus-common-library/stores/firestore";
import { Owner } from "../owner";

export enum Status {
    online = "online",
    inuse = "inuse",
    offline = "offline",
    issuing = "issuing",
}

export enum Kind {
    trafficSignal = "trafficSignal",
    gate = "gate",
    kart = "kart",
    projector = "projector",
}

export class Device extends Entity {
    static collectionName = "devices";

    @Var(String)
    @Input("text", { required: true })
    name: string = "";

    @Var(Kind)
    @Input("select", { required: true, options: Kind })
    kind: Kind = Kind.gate;

    @Var(Number)
    @Input("number")
    number: number = 0;

    @Var(String)
    @Input("text")
    reference?: string;

    @Var(String)
    @Input("text")
    version?: string;

    @Var(Status)
    status?: Status;

    @Var(Owner)
    owner?: Owner;

    @Input("text")
    get readableReference() {
        if (this.reference === undefined) {
            return "";
        }
        const matches = this.reference
            .matchAll(/[0-9a-f]{1,2}/g)
            .toArray()
            .map((match) => {
                return match[0];
            });
        console.log(this.reference, matches);
        return matches.join(" ");
    }

    toString() {
        return `${this.reference} (${this.kind}/${this.number}/${
            this.version !== undefined ? this.version : ""
        })`;
    }
}
