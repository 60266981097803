<script lang="ts">
import { defineComponent, toRefs } from "vue";
import { useUserSession } from "../../stores/userSession";

export function modelEvents() {
    return {
        onBeforeCreate(screen: any) {
            const { user } = useUserSession();
            if (user === null) throw new Error("No owner selected");
            screen.owner = user.owner;
        },

        onBeforeEdit(screen: any) {
            const { user } = useUserSession();
            if (user === null) throw new Error("No owner selected");
            screen.owner = user.owner;
        },
    };
}

export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const { form: device } = toRefs(props);

        return {
            device,
        };
    },
});
</script>

<template>
    <div>
        <VFieldModel v-model="device" property="name"></VFieldModel>
        <VField label=".reference.label">
            <VInput v-model="device.readableReference" disabled></VInput>
        </VField>
        <VField label=".number.label">
            <VInput v-model="device.number" disabled></VInput>
        </VField>
        <VFieldModel v-model="device" property="kind"></VFieldModel>
        <VField label=".version.label">
            <VInput v-model="device.version" disabled></VInput>
        </VField>
    </div>
</template>
