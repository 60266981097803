<script lang="ts">
import { defineComponent, toRefs, watch } from "vue";
import { useUserSession } from "../../stores/userSession";
import { Battery } from "../../models/race/kart";

export function modelEvents() {
    return {
        onBeforeCreate(screen: any) {
            const { user } = useUserSession();
            if (user === null) throw new Error("No owner selected");
            screen.owner = user.owner;
        },

        onBeforeEdit(screen: any) {
            const { user } = useUserSession();
            if (user === null) throw new Error("No owner selected");
            screen.owner = user.owner;
        },
    };
}

export default defineComponent({
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const { form: kart } = toRefs(props);

        if (kart.value.batteries === null || kart.value.batteries === undefined)
            kart.value.batteries = [];

        watch(
            () => kart.value.type.color,
            () => {
                kart.value.color = kart.value.type.color;
            },
        );

        return {
            kart,
            Battery,
        };
    },
});
</script>

<template>
    <div>
        <VFieldModel v-model="kart" property="name"></VFieldModel>
        <VField label=".reference.label">
            <VInput v-model="kart.readableReference" disabled></VInput>
        </VField>
        <VField label=".number.label">
            <VInput v-model="kart.number" disabled></VInput>
        </VField>
        <VFieldModel v-model="kart" property="type"></VFieldModel>
        <VField label=".version.label">
            <VInput v-model="kart.version" disabled></VInput>
        </VField>
        <VFieldModel v-model="kart" property="color"></VFieldModel>

        <VEntities
            v-slot="{ field }"
            v-model="kart.batteries"
            :model="Battery"
            multiple
            :opened="kart.$isNew()"
            property="batteries">
            <VFieldModel v-model="field.value" property="type"></VFieldModel>
            <VFieldModel
                v-model="field.value"
                property="voltage"
                :options="{ disabled: true }"></VFieldModel>
        </VEntities>
    </div>
</template>
