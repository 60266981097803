import type { Ref } from "vue";
import { computed } from "vue";
import { useCollection } from "addeus-common-library/stores/firestore";
import { useEmployeeSession } from "../../employeeSession";
import { Kart, Type as KartType } from "../../../models/race/kart";
import type { Status } from "../../../models/race/device";
import { Device, Kind } from "../../../models/race/device";
import type { Message } from "./socketMessage";
import { Kind as MsgKind, Type } from "./socketMessage";
import type { Race } from "../../../models/race";
import { Checkpoint, Driver, Lap } from "../../../models/race";
import { useLogger } from "../../logs";

const VALUES_KART_NUMBER = "kartNumber";
const VALUES_GATE_NUMBER = "gateNumber";
const VALUES_ROUND = "round";
const VALUES_STATUS = "status";
const VALUES_NUMBER = "number";
const VALUES_TIME = "time";
const VALUES_VERSION = "version";

const useMessageManager = (
    currentRace: Ref<Race | null>,
    onNewDevice: (device: Device | Kart, model: any) => {},
) => {
    const logger = useLogger();
    const employeeSession = useEmployeeSession();
    const karts = useCollection(Kart, {
        wheres: computed(() => [["owner", "==", employeeSession.user?.owner?.$getID()]]),
        limit: -1,
    });
    const types = useCollection(KartType, {
        limit: -1,
    });
    const devices = useCollection(Device, {
        wheres: computed(() => [["owner", "==", employeeSession.user?.owner?.$getID()]]),
        limit: -1,
    });

    function handleMessage(message: Message) {
        switch (message.device) {
            case MsgKind.kart:
                void kartMessage(message);
                break;
            case MsgKind.gate:
                void gateMessage(message);
                break;
            case MsgKind.trafficSignal:
                void trafficSignalMessage(message);
                break;

            default:
                break;
        }
    }

    async function retreiveDevice(reference: string): Promise<Device | undefined> {
        await devices.fetched();
        const device = devices.find((device) => device.reference === reference);

        return device;
    }

    async function retreiveDeviceByNumber(number: number, kind: Kind) {
        await devices.fetched();
        const device = devices.find((device: Device) => {
            return device.number === number && device.kind === kind;
        });

        return device;
    }

    async function retreiveGate(reference: string, number: number): Promise<Device> {
        if (employeeSession.user === null || employeeSession.user.owner === undefined) {
            throw new Error("No user connected or not part of any owner");
        }
        let gate = await retreiveDevice(reference);
        if (gate !== undefined && gate.number !== number) {
            gate.number = number;
            await gate.$save();
        }
        if (gate === undefined) {
            gate = new Device();
            gate.kind = Kind.gate;
            gate.reference = reference;
            gate.number = number;
            onNewDevice(gate, Device);
        }
        return gate;
    }

    async function retreiveGateByNumber(number: number): Promise<Device> {
        if (employeeSession.user === null || employeeSession.user.owner === undefined) {
            throw new Error("No user connected or not part of any owner");
        }
        let gate = await retreiveDeviceByNumber(number, Kind.gate);
        if (gate === undefined) {
            gate = new Device();
            gate.kind = Kind.gate;
            gate.number = number;
            onNewDevice(gate, Device);
        }
        return gate;
    }

    async function retreiveKart(reference: string, number: number = 0): Promise<Kart> {
        if (employeeSession.user === null || employeeSession.user.owner === undefined) {
            throw new Error("No user connected or not part of any owner");
        }
        await karts.fetched();
        let kart: Kart | undefined = karts.find((kart) => {
            return kart.reference === reference && kart.number === number;
        });
        // If there is no kart containing both reference and number, I then look for the reference only as it takes precedence.
        if (kart === undefined) {
            kart = karts.find((kart) => kart.reference === reference);
            if (kart !== undefined && number !== 0) {
                kart.number = number;
                await kart.$save();
            }
        }
        if (kart === undefined) {
            await types.fetched();
            kart = new Kart();
            kart.reference = reference;
            kart.number = number;
            kart.color = "#000000";
            kart.type = types.at(0);
            onNewDevice(kart, Kart);
        }
        return kart;
    }

    async function retreiveKartByNumber(number: number): Promise<Kart> {
        if (employeeSession.user === null || employeeSession.user.owner === undefined) {
            throw new Error("No user connected or not part of any owner");
        }
        await karts.fetched();
        let kart: Kart | undefined = karts.find((kart) => kart.number === number);

        if (kart === undefined) {
            kart = karts.find((kart) => kart.reference === number.toString());
            if (kart !== undefined) {
                kart.number = number;
                kart.reference = "";
                await kart.$save();
            }
        }

        if (kart === undefined) {
            await types.fetched();
            kart = new Kart();
            kart.number = number;
            kart.color = "#000000";
            kart.type = types.at(0);
            onNewDevice(kart, Kart);
        }
        return kart;
    }

    async function retreiveTrafficSignal(reference: string): Promise<Device> {
        if (employeeSession.user === null || employeeSession.user.owner === undefined) {
            throw new Error("No user connected or not part of any owner");
        }
        let trafficSignal = await retreiveDevice(reference);
        if (trafficSignal === undefined) {
            trafficSignal = new Device();
            trafficSignal.kind = Kind.trafficSignal;
            trafficSignal.reference = reference;
            // void trafficSignal.$save();
            onNewDevice(trafficSignal, Device);
        }
        return trafficSignal;
    }

    async function kartMessage(message: Message) {
        const num: number = message.values[VALUES_NUMBER] as number;
        const kart: Kart = await retreiveKart(message.reference, num);
        switch (message.type) {
            case Type.GATE:
                const number: number = message.values[VALUES_GATE_NUMBER] as number;
                const gate: Device = await retreiveGateByNumber(number);
                await employeeSession.user?.$getMetadata().refresh();
                await employeeSession.user?.owner?.$getMetadata().refresh();
                void logger.logOnKartPassesUnderCheckpoint(
                    employeeSession.user?.$getID(),
                    employeeSession.user?.owner?.$getID(),
                    gate.$getPlainForLogs(),
                    kart.$getPlainForLogs(),
                );
                if (currentRace.value === null) break;
                if (kart === undefined || gate === undefined) break;
                const round: number = message.values[VALUES_ROUND] as number;
                const time: number = message.values[VALUES_TIME] as number;
                addCheckpoint(kart, gate, round, time);
                break;
            case Type.VERSION:
                const { version } = message.values;
                if (kart.version !== (version as string)) {
                    kart.version = version as string;
                    await kart.$save();
                }
                break;
            case Type.STATUS:
                const { status } = message.values;
                if (kart.status !== (status as string)) {
                    kart.status = status as string as Status;
                    await kart.$save();
                }
                break;
            case Type.VOLTAGE:
                const { frontLeft, frontRight, backLeft, backRight } = message.values;
                if (kart.batteries.length < 4) {
                    break;
                }
                kart.batteries[0].voltage = frontLeft as number;
                kart.batteries[1].voltage = frontRight as number;
                kart.batteries[2].voltage = backLeft as number;
                kart.batteries[3].voltage = backRight as number;
                await kart.$save();
        }
    }

    async function gateMessage(message: Message) {
        const gateNumber: number = message.values[VALUES_NUMBER] as number;
        const gate: Device = await retreiveGate(message.reference, gateNumber);
        switch (message.type) {
            case Type.GATE:
                const number: number = message.values[VALUES_KART_NUMBER] as number;
                const kart: Kart = await retreiveKartByNumber(number);
                await employeeSession.user?.$getMetadata().refresh();
                await employeeSession.user?.owner?.$getMetadata().refresh();
                void logger.logOnKartPassesUnderCheckpoint(
                    employeeSession.user?.$getID(),
                    employeeSession.user?.owner?.$getID(),
                    gate.$getPlainForLogs(),
                    kart.$getPlainForLogs(),
                );
                if (currentRace.value === null) break;
                if (kart === undefined || gate === undefined) break;
                const round: number = message.values[VALUES_ROUND] as number;
                const time: number = message.values[VALUES_TIME] as number;
                addCheckpoint(kart, gate, round, time);
                break;
            case Type.STATUS:
                const status = message.values[VALUES_STATUS] as string;
                if (status === undefined) break;
                gate.status = status as Status;
                void gate.$save();
                break;
            case Type.VERSION:
                const version = message.values[VALUES_VERSION] as string;
                if (version === undefined) break;
                gate.version = version;
                void gate.$save();
                break;
        }
    }

    function addCheckpoint(kart: Kart, gate: Device, round: number, time: number) {
        if (currentRace.value === null) return;
        let driverIndex: number = currentRace.value.drivers.findIndex((driver) => {
            return driver.kart !== undefined && driver.kart?.$getID() === kart.$getID();
        });
        if (driverIndex === -1) {
            driverIndex = currentRace.value.drivers.length;
            const unknown: Driver = new Driver();
            unknown.kart = kart;
            unknown.kartType = kart.type;
            currentRace.value?.drivers.push(unknown);
        }
        const totalTime = currentRace.value.drivers[driverIndex].getTotalTime();
        const checkpoint = new Checkpoint();
        checkpoint.duration = time - totalTime;
        checkpoint.to = gate;
        if (currentRace.value.drivers[driverIndex].laps[round] === undefined) {
            const lap: Lap = new Lap();
            lap.kart = kart;
            // currentRace.value.drivers[driverIndex].laps[round] = lap;
            if (currentRace.value.drivers[driverIndex].laps[round - 1] !== undefined) {
                const nbChckpts =
                    currentRace.value.drivers[driverIndex].laps[round - 1].checkpoints
                        .length;
                checkpoint.from =
                    currentRace.value.drivers[driverIndex].laps[round - 1].checkpoints[
                        nbChckpts - 1
                    ].to;
            }
            lap.checkpoints.push(checkpoint);
            while (currentRace.value.drivers[driverIndex].laps.length < round - 1) {
                currentRace.value.drivers[driverIndex].laps.push(createEmptyLap());
            }
            currentRace.value.drivers[driverIndex].laps.push(lap);
        } else {
            const nbChckpts =
                currentRace.value.drivers[driverIndex].laps[round].checkpoints.length;
            const lastCheckpoint =
                currentRace.value.drivers[driverIndex].laps[round].checkpoints[
                    nbChckpts - 1
                ];
            checkpoint.from = lastCheckpoint.to;
            currentRace.value.drivers[driverIndex].laps[round].checkpoints.push(
                checkpoint,
            );
        }
        void currentRace.value.$save();
    }

    function createEmptyLap() {
        const emptyCheckpoint = new Checkpoint();
        emptyCheckpoint.duration = 0;
        const emptyLap = new Lap();
        emptyLap.checkpoints = new Array<Checkpoint>();
        emptyLap.checkpoints.push(emptyCheckpoint);
        return emptyLap;
    }

    async function trafficSignalMessage(message: Message) {
        const trafficSignal: Device = await retreiveTrafficSignal(message.reference);
        switch (message.type) {
            case Type.STATUS:
                const status = message.values[VALUES_STATUS] as string;
                if (status === undefined) break;
                trafficSignal.status = status as Status;
                void trafficSignal.$save();
                break;
            case Type.VERSION:
                const version = message.values[VALUES_VERSION] as string;
                if (version === undefined) break;
                trafficSignal.version = version;
                void trafficSignal.$save();
                break;
        }
    }

    return {
        handleMessage,
        async waitInitialization() {
            await employeeSession.user?.owner?.$getMetadata().isFullfilling;
            await devices.fetched();
            await karts.fetched();
        },
    };
};

export { useMessageManager };
